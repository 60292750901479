<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Children'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>
      <p>
        Simply having children can be reason enough for many people to make
        their Will. It is because children are often named in a Will
        (particularly as beneficiaries), that we ask you to start by naming your
        children.
      </p>
      <h5>Minor Children</h5>
      <p>
        Whilst children are young, is can be important to appoint guardians to
        look after them should the worst happen. Also where a minor child is
        left an inheritance it is important to name trustees to look after their
        inheritance until they are old enough to take control themselves.
      </p>
      <h5>Excluding Children</h5>
      <p>
        In certain cases a parent may wish to exclude a child from benefiting
        under their Will. This might be due to estrangement or simply because
        the child has significant personal assets themselves. Where a child is
        being excluded it is important to name them as being excluded, this is
        why we still ask you to add their details.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'ChildrenIntroModal',
  props: ['show'],
  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.children
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { children: false })
    }
  }
}
</script>

<style></style>
