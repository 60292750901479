<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 option-padding">
    <div
      v-on:click="
        $router.push('/profile/children').catch((error) => {
          console.log(error)
        })
      "
      class="person-edit-dots"
    >
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <div class="input-option selected child">
      <div class="image-wrapper">
        <img
          v-bind:src="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-group.png'"
          alt="mylastwill"
        />
      </div>
      <div class="person-details">
        <p>Future Children</p>
        <p class="relationship">One or More</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpectingChildren'
}
</script>
