<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in children"
        v-bind:key="id"
        v-bind:person="person"
        @edit="edit"
        @delete="setDeletePerson(person)"
        :type="'child'"
      >
      </Person>
      <ExpectingChildren
        v-if="$store.getters.personalDetails.children === 'expecting'"
      />
      <PersonAddQuad @addPerson="setNewPerson" :children="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :person="selectedPerson"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
      <DeleteModal
        v-show="show.deleteModal"
        @close="show.deleteModal = false"
        @confirm="deletePerson"
        v-bind:bodyText="deletePersonText"
        v-bind:loading="show.deleteProgress"
      >
      </DeleteModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'
import IntroModal from '../IntroModal'
import DeleteModal from '@/common/components/DeleteModal'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { http } from '@/services'
import ExpectingChildren from '@/views/pages/ChildrenPage/Questions/ExpectingChildren'

export default {
  name: 'ChildrenYourChildren',
  mixins: [willStringHelpers],
  components: {
    ExpectingChildren,
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad,
    IntroModal,
    DeleteModal
  },
  computed: {
    heading() {
      return 'What are the names of your children?'
    },
    number() {
      return '1'
    },
    ageRequirement() {
      if (this.$store.getters.personalDetails.country === 'Scotland')
        return '16'
      return '18'
    },
    subHeading() {
      if (this.partner) {
        return (
          'Please add all of you biological or adopted children (even if they are over ' +
          this.ageRequirement +
          '). If ' +
          this.firstWord(this.partner.full_name) +
          ' has children from a prior relationship you can add them also if you wish, although it is not essential. If you do not want a particular ' +
          'child to benefit under your Will it is still important to add their details. The children added will not automatically be included in ' +
          'your Will, this is just supporting information.'
        )
      } else {
        return (
          'Please add all of you biological or adopted children (even if they are over ' +
          this.ageRequirement +
          '). ' +
          'If you do not want a particular child to benefit under your Will it is still ' +
          'important to add their details. The children added will not automatically be ' +
          'included in your Will, this is just supporting information.'
        )
      }
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      return this.$store.getters.partner
    },
    forwardTo() {
      return '/children/check_and_confirm'
    },
    backTo() {
      return '/home'
    },
    isValidated() {
      if (this.children.length) return true
      return false
    },
    children() {
      return this.people.filter((person) => person.child)
    },
    deletePersonText() {
      if (this.selectedPerson) return this.selectedPerson.full_name
      return ''
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false,
        intro: true,
        deleteProgress: false,
        deleteModal: false
      }
    }
  },
  methods: {
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.show.deleteModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      if (value[0] === 'son' || value[0] === 'daughter' || value[0] === 'child')
        value.push('child')
      else if (value[0] === 'stepson' || value[0] === 'stepdaughter')
        value.push('child_step')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    },
    deletePerson() {
      this.show.deleteProgress = true
      http
        .delete('/wills/api/person', {
          params: { person_pk: this.selectedPerson.id }
        })
        .then((response) => {
          this.$notification.success('Child removed')
          this.$store.commit('personDelete', this.selectedPerson.id)
          this.closePersonModal()
          this.show.deleteProgress = false
        })
        .catch((error) => {
          console.log(error)
          this.closePersonModal()
          this.showDeleteProgress = false
        })
    },
    setDeletePerson(value) {
      this.selectedPerson = value
      this.show.deleteModal = true
    }
  }
}
</script>
