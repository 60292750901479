<template>
  <BaseModalSmall
    @close="$emit('close')"
    @confirm="$emit('confirm')"
    v-bind:isValidated="true"
    v-bind:loading="loading"
    v-bind:noSlide="true"
  >
    <template v-slot:header>Are you sure?</template>
    <template v-slot:body>{{ bodyText }} </template>
    <template v-slot:button>Delete</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
export default {
  name: 'DeleteModal',
  components: {
    BaseModalSmall
  },
  props: ['bodyText', 'loading']
}
</script>
